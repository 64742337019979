@font-face {
font-family: '__danjunghae_bc7184';
src: url(/_next/static/media/06ff735fe389cc60-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__danjunghae_Fallback_bc7184';src: local("Arial");ascent-override: 81.09%;descent-override: 25.56%;line-gap-override: 0.00%;size-adjust: 113.45%
}.__className_bc7184 {font-family: '__danjunghae_bc7184', '__danjunghae_Fallback_bc7184'
}.__variable_bc7184 {--font-danjunghae: '__danjunghae_bc7184', '__danjunghae_Fallback_bc7184'
}

@font-face {
font-family: '__retroChimps_0675de';
src: url(/_next/static/media/dee26baddf7f4917-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__retroChimps_Fallback_0675de';src: local("Arial");ascent-override: 63.84%;descent-override: 21.28%;line-gap-override: 8.51%;size-adjust: 117.49%
}.__className_0675de {font-family: '__retroChimps_0675de', '__retroChimps_Fallback_0675de'
}.__variable_0675de {--font-retro-chimps: '__retroChimps_0675de', '__retroChimps_Fallback_0675de'
}

@font-face {
font-family: '__retroChimpsExtrude_b7901b';
src: url(/_next/static/media/a9a8db9b4ab44384-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__retroChimpsExtrude_Fallback_b7901b';src: local("Arial");ascent-override: 63.85%;descent-override: 21.28%;line-gap-override: 8.51%;size-adjust: 117.46%
}.__className_b7901b {font-family: '__retroChimpsExtrude_b7901b', '__retroChimpsExtrude_Fallback_b7901b'
}.__variable_b7901b {--font-retro-chimps-extrude: '__retroChimpsExtrude_b7901b', '__retroChimpsExtrude_Fallback_b7901b'
}

@font-face {
font-family: '__pretendardVariable_ec6dbb';
src: url(/_next/static/media/0c7e3e2387401c47-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__pretendardVariable_Fallback_ec6dbb';src: local("Arial");ascent-override: 93.76%;descent-override: 23.75%;line-gap-override: 0.00%;size-adjust: 101.55%
}.__className_ec6dbb {font-family: '__pretendardVariable_ec6dbb', '__pretendardVariable_Fallback_ec6dbb'
}.__variable_ec6dbb {--font-pretendard: '__pretendardVariable_ec6dbb', '__pretendardVariable_Fallback_ec6dbb'
}

